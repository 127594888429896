import * as React from 'react';
import {CopyrightWrapper, CopyrightText} from './style';

type DefaultProps = {};

const Copyright: React.FC<DefaultProps> = ({}) => {
  return (
    <CopyrightWrapper pt={[6, 7]} pb={[3, 4]}>
      <CopyrightText fontSize={[12, 15]}>
        &copy; {new Date().getFullYear()} TENJIN DERMATOLOGY
      </CopyrightText>
    </CopyrightWrapper>
  );
};

export default Copyright;
