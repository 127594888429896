import * as React from 'react';
import {FooterWrapper} from './style';
import Menu from './Menu';
import Copyright from './Copyright';

type FooterProps = {};

const Footer: React.FunctionComponent<FooterProps> = ({}) => {
  return (
    <FooterWrapper>
      {/* <Menu /> */}
      <Copyright />
    </FooterWrapper>
  );
};

export default Footer;
