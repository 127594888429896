import React, { useState } from "react";
import { Link } from "gatsby";
import { Router, navigate } from "@reach/router";
import Helmet from "react-helmet";
import { useTransition, animated } from "react-spring";
import { ThemeProvider } from "styled-components";
import ScrollToTop from "react-scroll-up";

// meta
import useSiteMetadata from "./SiteMetadata";

// services
import SmoothScrolling from "./../services/anchorLink";

// components
import Footer from "../components/Footer";
import Navbar from "./Navbar";
import ScrollUpButton from "./ScrollUpButton";

// styles
import { theme } from "../theme";
import ResetCss from "./resetCSS";
import GlobalCss from "./globalCSS";
// import "./all.sass";

import "./scss/base.scss";
import "./scss/reset.scss";
import "./scss/style.scss";

// images
import LogoImage from "../img/logo.png";
import FreedaiyalImage from "../img/icons/icon_freedaiyal.svg";

type LayoutProps = {
  children: React.ReactNode;
};

const TemplateWrapper: React.FunctionComponent<LayoutProps> = ({
  children,
}) => {
  const { title, description } = useSiteMetadata();
  const [showSPMenu, setShowSPMenu] = useState(false);
  const [hoverPCMenu, setPCMenuHover] = useState(false);

  const handleSPMenu = (param) => {
    if (showSPMenu === true) {
      setShowSPMenu(false);
    } else {
      setShowSPMenu(true);
    }
  };

  const [menuOpen, setMenu] = useState(false);

  const handleBtnClick = () => setMenu(!menuOpen);

  const scrollToMedicalSection = () => {
    window.location.pathname === "/"
      ? SmoothScrolling.scrollTo("medical_section", null)
      : navigate(`/#medical-section`);
  };

  const scrollToPartsOfTroubleSection = () => {
    window.location.pathname === "/"
      ? SmoothScrolling.scrollTo("parts_of_trouble", null)
      : navigate(`/#parts-of-trouble`);
  };

  const scrollToAccessSection = () => {
    SmoothScrolling.scrollTo("access_section", null);
  };

  // indexの時は保険診療sectionへスムーススクロール、それ以外はページ移動
  const handleMenuAndScrollToMedicalSection = () => {
    setMenu(!menuOpen);
    window.location.pathname === "/"
      ? SmoothScrolling.scrollTo("medical_section", null)
      : navigate(`/#medical-section`);
  };

  // indexの時はお悩みの部位sectionへスムーススクロール、それ以外はページ移動
  const handleMenuAndScrollToPartsOfTroubleSection = () => {
    setMenu(!menuOpen);
    window.location.pathname === "/"
      ? SmoothScrolling.scrollTo("parts_of_trouble", null)
      : navigate(`/#parts-of-trouble`);
  };

  // アクセスへスムーススクロール
  const handleMenuAndScrollToAccessSection = () => {
    setMenu(!menuOpen);
    SmoothScrolling.scrollTo("access_section", null);
  };

  const transitions = useTransition(menuOpen, null, {
    config: { duration: 100 },
    from: { opacity: 0, transform: "translateY(-10%)" },
    enter: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: "translateY(-10%)" },
  });

  // メニューhover時
  const toggleHover = () => {
    setPCMenuHover(!hoverPCMenu);
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        {/* <ResetCss />
        <GlobalCss /> */}

        {/* <Navbar /> */}

        <header className="l-header">
          <div className="l-header__inner">
            <Link className="l-header__logo" to={"/"}>
              <img src={LogoImage} alt="" />
            </Link>

            <nav className="l-header__nav">
              <div className="l-header__nav__item">
                <Link
                  to={"/#medical_section"}
                  className="l-header__nav__item__link"
                  onClick={scrollToMedicalSection}
                >
                  保険診療
                </Link>
              </div>
              <div className="l-header__nav__item">
                <Link
                  className="l-header__nav__item__link"
                  to={"/#parts_of_trouble"}
                  onClick={scrollToPartsOfTroubleSection}
                >
                  美容診療（保険外）
                </Link>
              </div>
              <div className="l-header__nav__item">
                <Link
                  className="l-header__nav__item__link"
                  to={"/medical_hair"}
                >
                  医療脱毛
                </Link>
              </div>

              <div
                className="l-header__nav__item"
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
              >
                <span className="l-header__nav__item__link">料金表</span>

                {hoverPCMenu && (
                  <>
                    <div className="l-header__nav__item__menu">
                      <div className="l-header__nav__item__menu__inner">
                        <div className="l-header__nav__item__menu__item">
                          <Link
                            className="l-header__nav__item__menu__item__link"
                            to={"/beauty-medical-treatment/price"}
                          >
                            美容診療の料金表
                          </Link>
                        </div>
                        <div className="l-header__nav__item__menu__item">
                          <Link
                            className="l-header__nav__item__menu__item__link"
                            to={"/medical_hair/price"}
                          >
                            医療脱毛の料金表
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="l-header__nav__item">
                <a
                  href="https://plusmedicaiesuthetic-parentalconsentform.studio.site/"
                  target="_blank"
                  className="l-header__nav__item__link"
                >
                  親権者同意書
                </a>
              </div>

              <div className="l-header__nav__item">
                <Link className="l-header__nav__item__link" to={"/blog"}>
                  ブログ
                </Link>
              </div>
              <div className="l-header__nav__item">
                <a
                  className="l-header__nav__item__link"
                  href="#access_section"
                  onClick={scrollToAccessSection}
                >
                  アクセス
                </a>
              </div>
              {/* <div className="l-header__nav__item -fill-box">
                <a href="" className="l-header__nav__item__link">WEB予約</a>
              </div> */}
            </nav>

            {/* <button onClick={handleBtnClick}>Menu</button> */}
            {/* {transitions.map(({ item, key, props }) => {
              return (
                item && (
                  <animated.div
                    style={{ ...props, position: 'relative' }}
                    className="menu"
                    key={key}
                  >

                  </animated.div>
                )
              );
            })} */}

            <nav className="l-header__sp-nav">
              <div className="l-header__sp-nav__buttons">
                <div className="l-header__sp-nav__buttons__item">
                  <a
                    href="tel:08008051127"
                    className="l-header__sp-nav__button -tel"
                  >
                    <span className="l-header__sp-nav__button__text">
                      電話
                      <br />
                      予約
                    </span>
                  </a>
                </div>
              </div>
              <div className="l-header__sp-nav__menu" onClick={handleBtnClick}>
                <div className="l-header__sp-nav__menu__inner">
                  <span className="l-header__sp-nav__menu__line"></span>
                  <span className="l-header__sp-nav__menu__line"></span>
                  <span className="l-header__sp-nav__menu__line"></span>
                </div>
              </div>
            </nav>

            {transitions.map(({ item, key, props }) => {
              return (
                item && (
                  <animated.div
                    style={{
                      ...props,
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100vh",
                      minHeight: "inherit",
                    }}
                    className="menu"
                    key={key}
                  >
                    <div className="l-nav-conents">
                      <div className="l-nav-conents__inner">
                        <div className="l-nav-conents__head">
                          <div className="l-nav-conents__head__inner">
                            <div
                              className="l-nav-conents__head__close"
                              onClick={handleBtnClick}
                            ></div>
                            <div className="l-nav-conents__head__logo">
                              <img
                                src={LogoImage}
                                alt="天神皮ふ科"
                                className="l-nav-conents__head__logo__image"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="l-nav-conents__body">
                          <Link
                            to={"/"}
                            onClick={handleBtnClick}
                            className="l-nav-conents__body__link"
                          >
                            <span className="l-nav-conents__body__link__text">
                              トップページ
                            </span>
                          </Link>
                          <div
                            onClick={handleMenuAndScrollToMedicalSection}
                            className="l-nav-conents__body__link"
                          >
                            <span className="l-nav-conents__body__link__text">
                              保険診療
                            </span>
                          </div>
                          <a
                            href="#parts_of_trouble"
                            onClick={handleMenuAndScrollToPartsOfTroubleSection}
                            className="l-nav-conents__body__link"
                          >
                            <span className="l-nav-conents__body__link__text">
                              美容診療（保険外）
                            </span>
                          </a>
                          <Link
                            to={"/medical_hair"}
                            onClick={handleBtnClick}
                            className="l-nav-conents__body__link"
                          >
                            <span className="l-nav-conents__body__link__text">
                              医療脱毛
                            </span>
                          </Link>
                          <Link
                            to={"/beauty-medical-treatment/price"}
                            className="l-nav-conents__body__link"
                          >
                            <span className="l-nav-conents__body__link__text">
                              美容診療の料金表
                            </span>
                          </Link>
                          <Link
                            to={"/medical_hair/price"}
                            className="l-nav-conents__body__link"
                          >
                            <span className="l-nav-conents__body__link__text">
                              医療脱毛の料金表
                            </span>
                          </Link>

                          <a
                            href="https://plusmedicaiesuthetic-parentalconsentform.studio.site/"
                            target="_blank"
                            className="l-nav-conents__body__link"
                          >
                            <span className="l-nav-conents__body__link__text">
                              親権者同意書
                            </span>
                          </a>

                          <Link
                            to={"/blog"}
                            onClick={handleBtnClick}
                            className="l-nav-conents__body__link"
                          >
                            <span className="l-nav-conents__body__link__text">
                              ブログ
                            </span>
                          </Link>
                          <a
                            href="#access_section"
                            onClick={handleMenuAndScrollToAccessSection}
                            className="l-nav-conents__body__link -last"
                          >
                            <span className="l-nav-conents__body__link__text">
                              アクセス
                            </span>
                          </a>

                          <div className="l-nav-conents__body__buttons">
                            <div className="l-nav-conents__body__buttons__item">
                              <a
                                href="tel:08008051127"
                                className="c-button -freedaiyal -border-button"
                              >
                                <img
                                  className="c-button__icon"
                                  src={FreedaiyalImage}
                                  alt=""
                                />
                                <span className="c-button__text -large">
                                  0800-805-1127
                                </span>
                                <span className="c-button__text -small">
                                  ※保険診療は予約制ではありません。
                                </span>
                              </a>
                            </div>
                          </div>

                          <div className="l-nav-conents__body__opening__data">
                            <div className="l-nav-conents__body__opening__data__inner">
                              <p className="l-nav-conents__body__opening__data__title">
                                保険診療
                              </p>

                              <div className="l-nav-conents__body__opening__data__list">
                                <div className="l-nav-conents__body__opening__data__list__item">
                                  平日
                                </div>
                                <div className="l-nav-conents__body__opening__data__list__item">
                                  9:30 〜 19:00　(休診12:30〜14:30)
                                </div>
                              </div>
                              <p className="l-nav-conents__body__opening__data__text">
                                ※受付は診療終了時間の30分前までとなります。
                              </p>
                              <p className="l-nav-conents__body__opening__data__text">
                                ※予約制ではございませんので、そのままご来院ください（受付順にご案内致します）。
                              </p>
                            </div>
                          </div>

                          <div className="l-nav-conents__body__opening__data">
                            <div className="l-nav-conents__body__opening__data__inner">
                              <p className="l-nav-conents__body__opening__data__title">
                                美容診療(保険外)
                              </p>

                              <div className="l-nav-conents__body__opening__data__list">
                                <div className="l-nav-conents__body__opening__data__list__item">
                                  平日・土曜
                                </div>
                                <div className="l-nav-conents__body__opening__data__list__item">
                                  9:30〜19:00　(休診12:30〜14:30)
                                </div>
                              </div>

                              <div className="l-nav-conents__body__opening__data__list u-sp-mt20">
                                <div className="l-nav-conents__body__opening__data__list__item">
                                  日・祝
                                </div>
                                <div className="l-nav-conents__body__opening__data__list__item">
                                  9:30〜17:30　(休診12:30〜14:30)
                                </div>
                              </div>
                              <p className="l-nav-conents__body__opening__data__text">
                                ※完全ご予約制となります。
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </animated.div>
                )
              );
            })}
          </div>
        </header>

        <div>{children}</div>
        <Footer />

        <ScrollToTop
          showUnder={300}
          duration={700}
          easing="easeInOutCubic"
          style={{ bottom: 30, right: 20 }}
        >
          <ScrollUpButton />
        </ScrollToTop>
      </>
    </ThemeProvider>
  );
};

export default TemplateWrapper;
